<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong
						style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Customers</strong>
				</p>
			</div>
		</section>
		<section class="container-1200" style="margin: 40px auto;">
			<div style="text-align: justify;line-height: 30px;font-size: 16px;margin-top: 100px;"
				class="layui-collapse">
				<div style="margin-top:10px;width:100%;text-align: justify;margin-top: 10px;padding:0px 10px;">
					<div class="customerImg" style="display: flex;">
						<div class="customerImg1"><a href="https://www.volksonpress.com/"><img
									src="../../assets/images/customer1.png" alt=""></a></div>
						<div class="customerImg1"><a href="https://www.zibelinepub.com/"><img
									src="../../assets/images/customer2.png" alt=""></a></div>
						<!-- <div class="customerImg1"><a href="https://www.wsp-publishing.com/"><img
									src="../../assets/images/customer3.png" alt=""></a></div> -->
					</div>

				</div>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<!-- <div style="height: 20px;"> -->
				<div
						style="font-size: 24px;font-family: 'Bebas Neue', cursive;">Comments</div>
				
			<!-- </div> -->
		</section>
		<section class="container-1200" style="margin: 40px auto;">
			<!-- <div class="comment" v-for="item in commemt_about" :key="item">
				<div class="avatar">
					<img :src="item.img" alt="">
				</div>
				<div class="comment_content">
					<p>{{item.name}}</p>
					<p>{{item.profile}}</p>
				</div>
			</div> -->

			<el-carousel :interval="5000">
				<el-carousel-item v-for="item in commemt_about" :key="item">
					<div class="comment">
						<div class="avatar">
							<img class="avatarImage" :src="item.img" alt="">
						</div>
						<div class="comment_content">
							<p>{{item.name}}</p>
							<p>{{item.profile}}</p>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</section>
	</div>

</template>
<script>
	export default {
		name: "customers",
		data() {
			return {
				commemt_about: [{
						img: require('@/assets/images/team1.png'), //头像
						name: 'Hossein Moayedi', //名字
						profile: 'Duy Tân University, Engineering Computer Science Chemistry Environmental Sciences & Ecology Materials Science.', //简介
					},
					{
						img: require('@/assets/images/team2.png'), //头像
						name: 'AL DIWANIYAH, IRAQ', //名字
						profile: 'University of Al-QadisiyahSch Educ,Science & Technology - Other Topics.', //简介
					}, {
						img: require('@/assets/images/team3.png'), //头像
						name: 'Dr. Abdulrahman S Bazaid', //名字
						profile: 'Department of Medical Laboratory Science, College of Applied Medical Sciences, University of Ha’il, Ha’il.', //简介
					},
				]
			};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {

		},
	}
</script>

<style lang="scss" scoped>
	.customerImg1 {
		margin-left: 15px;
	}

	.customerImg img {
		width: 200px;
		height: 70px;
		margin-bottom: 15px;
	}

	.comment {
		border: 1px solid #eee;
		display: flex;
		align-items: center;
	}

	.avatar {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.avatarImage{
		padding: 25px 0px;
		width: 120px;
		height: 120px;
		border-radius: 50%;
	}

	.comment_content {
		flex: 8;
	}

	.comment_content p {
		padding: 0px 20px;
	}

	/* 轮播形式 */
	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;
	}
	
	.el-carousel__item{
		height:unset;
	}
</style>